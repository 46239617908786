import React from 'react';
import Button from '../../Button';
import { careerDetailContent } from '../../../content/index';

function CareerNotFound() {
  const { header, description, url, button } = careerDetailContent.redirect;

  return (
    <section>
      <div className="flex flex-col items-start">
        <h1 className="font-extrabold text-4xl text-black">{header}</h1>
      </div>

      <div className="mt-4.2 md:mt-5.5">
        <p data-testid="page-detail-not-found-description">{description}</p>
      </div>

      <Button
        className="mt-4.2 md:mt-5.5 py-1 rounded-sm text-16px font-semibold tracking-tight uppercase pointer-fine:hover:transition-all duration-300 pointer-fine:hover:bg-secondary bg-primary text-white"
        variant="blue"
        text={`${button}`}
        to={`${url}`}
      />
    </section>
  );
}

export default CareerNotFound;
